<template>
  <div id="defaultId1" class="company_detail">
    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false" >
      <div>
        <h2 class="operations-head flex_center_between_box">
          <span class="head-titel detail_title_20">Top 10 Export Products</span>
        </h2>
        <div class="flex_center_between_box">
          <div class="Products_lesft">
            <div ref="SupplyChainEchart1" style="width: 95%; height: 38rem"></div>
          </div>
          <div class="table-biao" style="position: relative;">
    
    <el-table :data="ImporttableData" ref="table" empty-text="No Data" style="width: 100%" class="table_height_30 table_font_size_14 table_contentHeight_40">
      <el-table-column prop="Date" label="Date"  align="left"  show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="table-content">{{ scope.row.Date }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="HSCode" label="HS Code"  align="left" show-overflow-tooltip>
        
        <template slot-scope="scope">
          <span class="table-content">{{ scope.row.HSCode }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Products" label="Products" width="300" align="left" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="" style="color: #1290C9;cursor: pointer;">{{ scope.row.Products }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="MajorBuyers" label="Major Buyers" width="300" align="left" show-overflow-tooltip>

        <template slot-scope="scope">
          <span class="" style="color: #1290C9;cursor: pointer;">{{ scope.row.MajorBuyers }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="DestinationCountry" label="Destination " width="180" align="left" show-overflow-tooltip>
      
        <template slot-scope="scope">
          <span >{{ scope.row.DestinationCountry }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Amount" label="transaction" align="center">
        <template slot-scope="scope">
          <div class="flex_center">
            <span class="table-content">{{ scope.row.Amount }}</span>
            <!-- </el-tooltip> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    
  </div>
        </div>
     
      </div>
    </gui_card>
    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false" style="margin-top: 10px;">
      <div>
        <h2 class="operations-head flex_center_between_box">
          <span class="head-titel detail_title_20">Top 10 Imported Products</span>
        </h2>
        <div class="flex_center_between_box">
          <div class="Products_lesft">
            <div ref="SupplyChainEchart" style="width: 90%; height: 38rem"></div>
          </div>
          <div class="table-biao" style="position: relative;">
    
    <el-table :data="ImporttableData" ref="table" empty-text="No Data" style="width: 100%" class="table_height_30 table_font_size_14 table_contentHeight_40">
      <el-table-column prop="Date" label="Date"  align="left">
        <template slot-scope="scope">
          <span class="table-content">{{ scope.row.Date }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="HSCode" label="HS Code"  align="left" show-overflow-tooltip>
        
        <template slot-scope="scope">
          <span class="table-content">{{ scope.row.HSCode }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Products" label="Products" width="300" align="left" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="" style="color: #1290C9;cursor: pointer;">{{ scope.row.Products }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="MajorBuyers" label="Major Suppliers" width="300" align="left" show-overflow-tooltip>

        <template slot-scope="scope">
          <span class="" style="color: #1290C9;cursor: pointer;">{{ scope.row.MajorBuyers }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="DestinationCountry" label="Origin " width="150" align="left" show-overflow-tooltip>
      
        <template slot-scope="scope">
          <span >{{ scope.row.DestinationCountry }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Amount" label="transaction" align="center">
        <template slot-scope="scope">
          <div class="flex_center">
            <span class="table-content">{{ scope.row.Amount }}</span>
            <!-- </el-tooltip> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    
  </div>
        </div>
     
      </div>
    </gui_card>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>

  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import {
  getNews,
  getfilterConditions,
  getPdfLink,
} from "@/api/companyApi";
import rectcard from "@/components/rectCard/index.vue";
import content_text from "@/components/text_content/index.vue";
import gui_card from "@/components/gui_card/gui_card.vue";
import ReLogin from "@/components/ReLogin.vue";
//importEnd

export default {
  name: "",

  props: {},

  components: {
    // gui_card start
    gui_card,
    // gui_card end

    rectcard,
    content_text,
    ReLogin,
  },
  computed: {},
  mounted() {

    this.getNewsList()
    this.SourceList()
    this.suppleEchart();

  },
  data() {
    return {
      reLoginVis: false,
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      keyNum: 0,
      SearchHscode: "",
      SelectHscode: '',
      isshowSearchHscode: false,
      isshowSearchHscodetwo: false,
      ImporttableData: [
        {
          Date: "02-06-24",
          Products:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
            MajorBuyers: "dpa international Service in English",
          HSCode: "12312312",
          DestinationCountry:'CHina',
          Amount:'0.001'
        },
        {
          Date: "02-06-24",
          Products:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
            MajorBuyers: "dpa international Service in English",
          HSCode: "12312312",
          DestinationCountry:'CHina',
          Amount:'0.001'
        },
        {
          Date: "02-06-24",
          Products:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
            MajorBuyers: "dpa international Service in English",
          HSCode: "12312312",
          DestinationCountry:'CHina',
          Amount:'0.001'
        },
        {
          Date: "02-06-24",
          Products:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
            MajorBuyers: "dpa international Service in English",
          HSCode: "12312312",
          DestinationCountry:'CHina',
          Amount:'0.001'
        },
        {
          Date: "02-06-24",
          Products:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
            MajorBuyers: "dpa international Service in English",
          HSCode: "12312312",
          DestinationCountry:'CHina',
          Amount:'0.001'
        },
        {
          Date: "02-06-24",
          Products:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
            MajorBuyers: "dpa international Service in English",
          HSCode: "12312312",
          DestinationCountry:'CHina',
          Amount:'0.001'
        },
   
      ],
      options: [{
        value: 'text1',
        label: 'text1'
      }, {
        value: 'text2',
        label: 'text2'
      }
      ],
      importTrendListEcharts:[
    {
        "code": "2016",
        "dateCount": 7822,
        "value": "2016",
        "valueEn": "2016",
        name:'apple1',
        "percentage": null,
        "supplierCountryCode": null,
        "supplierCountryCn": null,
        "supplierCountryEn": null,
        "totalCount": null
    },
    {
        "code": "2017",
        "dateCount": 7518,
        "value": "2017",
        "valueEn": "2017",
        name:'apple2',
        "percentage": null,
        "supplierCountryCode": null,
        "supplierCountryCn": null,
        "supplierCountryEn": null,
        "totalCount": null
    },
    {
        "code": "2018",
        "dateCount": 7757,
        "value": "2018",
        "valueEn": "2018",
        name:'apple3',
        "percentage": null,
        "supplierCountryCode": null,
        "supplierCountryCn": null,
        "supplierCountryEn": null,
        "totalCount": null
    },
    {
        "code": "2019",
        "dateCount": 7980,
        "value": "2019",
        "valueEn": "2019",
        "percentage": null,
        name:'apple4',
        "supplierCountryCode": null,
        "supplierCountryCn": null,
        "supplierCountryEn": null,
        "totalCount": null
    },
    {
        "code": "2020",
        "dateCount": 4740,
        "value": "2020",
        "valueEn": "2020",
        "percentage": null,
        name:'apple5',
        "supplierCountryCode": null,
        "supplierCountryCn": null,
        "supplierCountryEn": null,
        "totalCount": null
    },
    {
        "code": "2021",
        "dateCount": 5964,
        "value": "2021",
        "valueEn": "2021",
        "percentage": null,
        name:'apple6',
        "supplierCountryCode": null,
        "supplierCountryCn": null,
        "supplierCountryEn": null,
        "totalCount": null
    }
],
      newDialog: false,
      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },
  methods: {
    getData() {
      this.suppleEchart();
    },
    suppleEchart() {
      let that = this;
      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);
      this.echartsBox1 = this.$echarts.init(this.$refs.SupplyChainEchart1)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',

          }
        },
        grid: {
          left: '2%', // 设置左侧边距
          right: '4%',
          top: '12%',
          containLabel: true
        },
        legend: {
          right: '5px',
          top: '0px',
          //  width: '200px'
          // 其他图例配置项
          // ...
        },
        yAxis: {
          type: 'category',
          boundaryGap: false, 
          axisLine: {
            show: true,
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.2)',
            },
        },
        triggerEvent: true,
          axisLabel: {
            
            show: false,
     },
          data: that.importTrendListEcharts.map(item => item.name),
          axisTick: {
            show: false // 不显示 x 轴上的间隔点
          }
        },
        xAxis: [
          
          {
            type: 'value',
            axisLine: {
            show: false,
          
        },
        position: 'bottom', // 将 xAxis 放置在底部
        offset: 10, // 向下移动 10px
            axisTick: {
            show: false // 不显示 x 轴上的间隔点
          },

          
          },

        ],
        series: [
          {
            name: 'Transactions',
            type: 'bar',
            barWidth: '25',
            showBackground: true,
            backgroundStyle: { color: '#F5F5F5', },
            itemStyle: { color: '#009EA7' },
            label: {
              show: true,
              color: '#fff',
              z: 3,
         
              position: 'insideRight',
              //   formatter: '{c}%',
              formatter: function (params) {
                return (params['name'])
              }
            },
            emphasis: {
              itemStyle: {
                color: '#009EA7',
              },
            },
            data: that.importTrendListEcharts.map(item => item.dateCount)
          },
      
        ]
      };
      const option1 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',

          }
        },
        grid: {
          left: '2%', // 设置左侧边距
          right: '4%',
          top: '12%',
          bottom: '0%',
          containLabel: true
        },
        legend: {
          right: '5px',
          top: '0px',
          //  width: '200px'
          // 其他图例配置项
          // ...
        },
        yAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.2)',
            },
        },
        triggerEvent: true,
          axisLabel: {
            
            show: false,
     },
          data: that.importTrendListEcharts.map(item => item.name),
          axisTick: {
            show: false // 不显示 x 轴上的间隔点
          }
        },
        xAxis: [
          
          {
            type: 'value',
            axisLine: {
            show: false,
          
        },
            axisTick: {
            show: false // 不显示 x 轴上的间隔点
          },

          
          },

        ],
        series: [
          {
            name: 'Transactions',
            type: 'bar',
            barWidth: '25',
            showBackground: true,
            backgroundStyle: { color: '#F5F5F5', },
            itemStyle: { color: '#009EA7' },
            label: {
              show: true,
              color: '#fff',
              z: 3,
         
              position: 'insideRight',
              //   formatter: '{c}%',
              formatter: function (params) {
                return (params['name'])
              }
            },
            emphasis: {
              itemStyle: {
                color: '#009EA7',
              },
            },
            data: that.importTrendListEcharts.map(item => item.dateCount)
          },
      
        ]
      };
      this.echartsBox.setOption(option);
      this.echartsBox1.setOption(option); 
   
    },
    setid(row) {
      if (row.link == undefined) {
        getPdfLink({ articleRef: row.id }).then(res1 => {
          // let res1 = JSON.parse(crypto.decrypt(res));

          if (res1.data.includes('http')) {
            let routeData = res1.data;
            window.open(routeData, '_blank');
          } else {
            let routeData = 'http://' + res1.data;
            window.open(routeData, '_blank');
          }
          // let routeData = res1.data;
          // window.open(routeData, '_blank');
        });
      }
      if (row.link.includes('http')) {

        let routeData = row.link;
        window.open(routeData, '_blank');
      } else {
        let routeData = 'http://' + row.link;
        window.open(routeData, '_blank');
      }
      // let routeData = row.url;
      // window.open(routeData, '_blank');
    },
    SourceList() {

    },
    getNewsList() {
 
      // getfilterConditions()
    },
    showSearchHscode() { },
    changSeach() {},
    searchPersonByName() {

    },
    ShowAll() {
 
    
    },
  },

};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}

.table-biao {
  height: auto;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 0.2857142857142857rem;
  margin-top: 1.4285714285714286rem;
}

.operations-head {
  font-size: 2.142857142857143rem;
  /* font-family: "Arial Bold"; */
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-family: 'DMSans Bold';
  padding-left: 1.4285714285714286rem !important;
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
  font-family: 'DMSans Bold';
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}

@media screen and (max-width: 1441px) {
  .contclass {
  font-size: 14px;
 
}
}


.table_add {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_add_Text {
  font-family: 'DMSans Bold';
  text-align: left;
}

.table-content {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  display: inline-block;
  line-height: 2.142857142857143rem;
}

.uc1096 {
  height: 100%;
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.impact_status {
  width: 2.2857142857142856rem;
  height: 2.2857142857142856rem;
  text-align: center;
  border-radius: 50%;
}

.impact_status img {
  width: 1.1428571428571428rem;
  height: 1.1428571428571428rem;
  margin-top: 0.5rem;
}

.impact_status.green {
  background: #55b419;
}

.impact_status.hui {
  background: #ccc;
}

.impact_status.blue {
  background: #1290c9;
}

.impact_status.red {
  background: #ff7600;
}

.input-str {
  position: absolute;
  top: 0.2857142857142857rem;
  left: 13.214285714285714rem;
  z-index: 99;
  text-align: 7.142857142857143rem;
  width: 26%;
}

.input-str /deep/ .el-input__inner {
  margin-top: -0.14285714285714285rem;
}

/deep/.el-input__inner:focus {
  border: 0.07142857142857142rem solid #8497AB;
}

.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}
.Products_lesft{
  width: 35%;
  margin-right: 3.5rem;
}
.table-biao{
  width: 100%;
}
</style>
